import axios from 'axios'
import { AuthedUser } from './types'

export class AxiosService {
  async get<T>(params: { url: string; currentUser: AuthedUser }): Promise<T> {
    const { data } = await axios.get(params.url, {
      headers: {
        'x-stoner-yoga-auth': params.currentUser?.credential,
      },
    })
    return data
  }

  async getPublic<T>(params: { url: string }): Promise<T> {
    const { data } = await axios.get(params.url, {
      headers: {
        'x-ntntn-freemium': true,
      },
    })
    return data
  }

  async put<T>(params: {
    url: string
    data: any
    currentUser: AuthedUser
  }): Promise<T> {
    const { data } = await axios.put(params.url, params.data, {
      headers: {
        'x-stoner-yoga-auth': params.currentUser?.credential,
      },
    })
    return data
  }

  async postFile<T>(params: {
    url: string
    data: any
    currentUser: AuthedUser
    file: File
  }): Promise<T> {
    const formData = new FormData()
    formData.append('file', params.file)
    formData.append('data', JSON.stringify(params.data))
    const { data } = await axios.post(params.url, formData, {
      headers: {
        'x-stoner-yoga-auth': params.currentUser?.credential,
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async delete<T>(params: {
    url: string
    currentUser: AuthedUser
  }): Promise<T> {
    const { data } = await axios.delete(params.url, {
      headers: {
        'x-stoner-yoga-auth': params.currentUser?.credential,
      },
    })
    return data
  }
}
