import axios from 'axios'

export class AuthService {
  constructor(private readonly config: { baseUrl: string; prefix: string }) {}
  async exchange(token: string) {
    const { data } = await axios.put(
      `${this.config.baseUrl}/${this.config.prefix}/login`,
      { credential: token },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
    return data
  }
}
