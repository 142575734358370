import {
  Box,
  CardCover,
  CssVarsProvider,
  Drawer,
  extendTheme,
  Grid,
  IconButton,
  Input,
  List,
  ListItemButton,
  ModalClose,
  Sheet,
  Typography,
} from '@mui/joy'
import React, { ReactNode, useEffect, useState } from 'react'
import styles from './page-wrapper.module.css'
import { Menu, Search } from '@mui/icons-material'
import { App as CapApp } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { useAuth0 } from '@auth0/auth0-react'
import useYogaStore from '../../hooks/use-yoga-store'
import { ServiceFactory } from '../../service/factory'
import LoginButton from './auth/login-button'
import LogoutButton from './auth/logout-button'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

interface Props {
  children: ReactNode
}

const PageWrapper: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const { handleRedirectCallback } = useAuth0()

  const currentUser = useYogaStore((state) => state.currentUser)
  const setCurrentUser = useYogaStore((state) => state.setCurrentUser)

  const navigate = useNavigate()
  // const currentFlowId = useYogaStore((state) => state.currentFlowId)
  // const setCurrentFlowId = useYogaStore((state) => state.setCurrentFlowId)

  const baseTheme = extendTheme()
  const darkOnlyTheme = extendTheme({
    cssVarPrefix: 'sidebar_',
    colorSchemes: {
      light: baseTheme.colorSchemes.dark,
      dark: baseTheme.colorSchemes.dark,
    },
  })

  const { user, getIdTokenClaims } = useAuth0()
  useEffect(() => {
    if (user) {
      getIdTokenClaims().then(async (token) => {
        if (token) {
          const user = await ServiceFactory.auth.exchange(
            token?.__raw as string,
          )
          setCurrentUser(user)
          ReactGA.send({
            hitType: 'login',
            eventCategory: 'User',
            eventAction: 'login',
            eventLabel: user.email,
          })

          // window.location.href = "/home";
        }
      })
    }
  }, [user, getIdTokenClaims, setCurrentUser])

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (
        url.includes('state') &&
        (url.includes('code') || url.includes('error'))
      ) {
        await handleRedirectCallback(url)
      }
      // No-op on Android
      await Browser.close()
    })
  }, [handleRedirectCallback])

  return (
    <CssVarsProvider theme={darkOnlyTheme}>
      <Sheet
        sx={{
          height: '100vh',
          overflowX: 'hidden',
        }}
        className={styles.pageWrapper}
      >
        <IconButton
          variant="outlined"
          color="neutral"
          sx={{ position: 'fixed', top: 0, left: 0, zIndex: 100 }}
          onClick={() => setOpen(true)}
        >
          <Menu />
        </IconButton>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              ml: 'auto',
              mt: 1,
              mr: 2,
            }}
          >
            <Typography
              component="label"
              htmlFor="close-icon"
              fontSize="sm"
              fontWeight="lg"
              sx={{ cursor: 'pointer' }}
            >
              Close
            </Typography>
            <ModalClose id="close-icon" sx={{ position: 'initial' }} />
          </Box>
          {!currentUser ? <LoginButton /> : <LogoutButton />}

          <List
            size="lg"
            component="nav"
            sx={{
              flex: 'none',
              fontSize: 'sm',
              '& > div': { justifyContent: 'center' },
            }}
          >
            <ListItemButton
              sx={{ fontWeight: 'lg' }}
              onClick={() => {
                navigate('/')
                // setCurrentFlowId(undefined)
                setOpen(false)
              }}
            >
              Home
            </ListItemButton>
            <ListItemButton>
              <a
                href="https://discord.gg/gRnWXSSpE2"
                className={styles.social}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: '35px' }}
                  src="/social_media/discord_icon.svg"
                  alt="Discord Logo"
                />
              </a>{' '}
              Join us on Discord
            </ListItemButton>
          </List>
        </Drawer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            maxWidth: '1366px',
            margin: 'auto',
          }}
        >
          <CardCover
            sx={{
              backdropFilter: 'blur(10px) saturate(50%)',
              opacity: 0.15,
              filter: 'blur(25px)  saturate(150%)',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backgroundImage: `url('/card-cover-2.webp')`,
              backgroundSize: 'cover',
            }}
          ></CardCover>
          {children}
        </Box>
      </Sheet>
    </CssVarsProvider>
  )
}

export default PageWrapper
