import { AxiosService } from '../axios-service'
import { AuthedUser, Flow, UnsavedFlow } from '../types'

export class FlowService extends AxiosService {
  constructor(private readonly config: { baseUrl: string; prefix: string }) {
    super()
  }

  async getFlows(currentUser: AuthedUser): Promise<Flow[]> {
    return this.get<Flow[]>({
      url: `${this.config.baseUrl}/${this.config.prefix}`,
      currentUser,
    })
  }

  async listPublic(setFlows: (flows: Flow[]) => void) {
    setFlows(
      await this.getPublic<Flow[]>({
        url: `${this.config.baseUrl}/${this.config.prefix}/public`,
      }),
    )
  }

  async play(id: string, currentUser?: AuthedUser): Promise<Flow> {
    if (!currentUser) {
      return this.getPublic<Flow>({
        url: `${this.config.baseUrl}/${this.config.prefix}/public/play/${id}`,
      })
    }
    return this.get<Flow>({
      url: `${this.config.baseUrl}/${this.config.prefix}/play/${id}`,
      currentUser,
    })
  }

  async upsert(
    flow: Flow | UnsavedFlow,
    currentUser: AuthedUser,
  ): Promise<Flow> {
    return this.put<Flow>({
      url: `${this.config.baseUrl}/${this.config.prefix}`,
      data: flow,
      currentUser,
    })
  }

  async getFlow(flowId: string, currentUser: AuthedUser): Promise<Flow> {
    const flow = await this.get<Flow>({
      url: `${this.config.baseUrl}/${this.config.prefix}/${flowId}`,
      currentUser,
    })

    return flow
  }

  async deleteFlow(flowId: string, currentUser: AuthedUser): Promise<void> {
    await this.delete({
      url: `${this.config.baseUrl}/${this.config.prefix}/${flowId}`,
      currentUser,
    })

    await this.getFlows(currentUser)
  }
}
