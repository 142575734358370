import { Capacitor } from '@capacitor/core'

const auth0DomainDev = 'dev-fyngeglhhgg65egh.us.auth0.com'
const auth0DomainProd = 'dev-fyngeglhhgg65egh.us.auth0.com'

export type AppConfig = {
  baseUrl: string
  isDev: boolean
  // siteUrl: string
  wssUrl: string
  stripeTokenLink: string
  auth0: {
    domain: string
    clientId: string
    redirectUri: {
      login: string
      logout: string
    }
  }
}

const config: AppConfig = {
  baseUrl:
    process.env.NODE_ENV === 'production'
      ? 'https://stoner-yoga.onrender.com/api/v1'
      : (process.env.WEBSOCKET_URL ?? Capacitor.getPlatform() === 'web')
        ? `http://localhost:3080/api/v1`
        : `http://192.168.0.13:3080/api/v1`,

  wssUrl:
    process.env.NODE_ENV === 'production'
      ? 'wss://stoner-yoga.onrender.com'
      : 'ws://localhost:3080',

  isDev: process.env.NODE_ENV !== 'production',

  // siteUrl:
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://stoner-yoga.onrender.com'
  //     : 'https://localhost:3000',
  // // : "https://yoga.ntntn.io",

  stripeTokenLink:
    process.env.NODE_ENV === 'production'
      ? `https://buy.stripe.com/6oE4id9Ex4gKcJGaEF`
      : `https://buy.stripe.com/test_14k9EtbjI96Q944145`,
  auth0: {
    domain:
      process.env.NODE_ENV === 'production' ? auth0DomainProd : auth0DomainDev,
    clientId:
      process.env.NODE_ENV === 'production'
        ? 'pVSFNqBUTii1STHq9c6pC10YfSBIouUH'
        : 'pVSFNqBUTii1STHq9c6pC10YfSBIouUH',
    redirectUri: {
      login:
        process.env.NODE_ENV === 'production'
          ? Capacitor.getPlatform() === 'web'
            ? 'https://yoga.ntntn.io'
            : `io.ntntn.yoga://${auth0DomainDev}/capacitor/io.ntntn.yoga/callback`
          : Capacitor.getPlatform() === 'web'
            ? 'https://localhost:8080'
            : `io.ntntn.yoga://${auth0DomainDev}/capacitor/io.ntntn.yoga/callback`,

      logout:
        process.env.NODE_ENV === 'production'
          ? Capacitor.getPlatform() === 'web'
            ? 'https://yoga.ntntn.io'
            : // ? "https://yoga.ntntn.io"
              `io.ntntn.yoga://${auth0DomainDev}/capacitor/io.ntntn.yoga/callback`
          : Capacitor.getPlatform() === 'web'
            ? 'https://localhost:8080'
            : `io.ntntn.yoga://${auth0DomainDev}/capacitor/io.ntntn.yoga/callback`,
    },
  },
}

export default config
