export type OpenAIPose = {
  name: string
  id: string
  focus: string[]
  level: number
  breath: string
  centered: boolean
  to?: string[]
  from?: string[]
  order?: number
  flowingId?: string
}

export type OpenAISequence = {
  name: string
  id: string
  poses: OpenAIPose[]
  flowingId?: string
}

export type AIGeneratedMovement = {
  poseId: string
  poseName: string
  orientation: 'center' | 'left' | 'right'
  breath: 'inhale' | 'exhale'
  holdFor: number
  flowingId: string
  isStartingMovement: boolean
  isEndingMovement: boolean
}

export type AIGeneratedSequence = {
  name: string
  description: string
  fromPoseIndex: number
  poses: AIGeneratedMovement[]
}

export type Focus =
  | 'core'
  | 'starting pose'
  | 'forward bend'
  | 'restorative'
  | 'arm balance'
  | 'flexibility'
  | 'seated'
  | 'inversion'
  | 'balance'
  | 'chest opener'
  | 'standing'
  | 'hip opener'
  | 'warm up'
  | 'backbend'
  | 'twist'
  | 'bind'
  | 'strength'

export type Level = 'beginner' | 'intermediate' | 'advanced'

export type Orientation = 'left' | 'right' | 'center'
export type Facing = 'left' | 'right' | 'forward' | 'backward'

export type BreathDirection = 'inhale' | 'exhale'

export type Chakra = {
  name:
    | 'root'
    | 'sacral'
    | 'solarPlexus'
    | 'heart'
    | 'throat'
    | 'thirdEye'
    | 'crown'
  color: 'red' | 'orange' | 'yellow' | 'green' | 'blue' | 'indigo' | 'violet'
  description: string
}

export const RootChakra: Chakra = {
  color: 'red',
  name: 'root',
  description: 'I am',
}

export const SacralChakra: Chakra = {
  color: 'orange',
  name: 'sacral',
  description: 'I feel',
}

export const SolarPlexusChakra: Chakra = {
  color: 'yellow',
  name: 'solarPlexus',
  description: 'I do',
}

export const HeartChakra: Chakra = {
  color: 'green',
  name: 'heart',
  description: 'I love',
}

export const ThroatChakra: Chakra = {
  color: 'blue',
  name: 'throat',
  description: 'I talk',
}

export const ThirdEyeChakra: Chakra = {
  color: 'indigo',
  name: 'thirdEye',
  description: 'I see',
}

export const CrownChakra: Chakra = {
  color: 'violet',
  name: 'crown',
  description: 'I understand',
}

/**-----------------------------------------------
Flow: 
  FlowingMovements: 
    FlowingPose, or
    FlowingSequence
      overrides: SequencedPose: { [order]: SequencedPose }
    
----------------------------------------------- */

export type FlowingTransition = {
  id?: string
  flowId?: string
  from: FlowingMovement
  to?: FlowingMovement
  videoId?: string
  audioId?: string
  order: number
  audio?: AudioClip
  video?: VideoClip
  instruction: string
}

interface BaseFlowing {
  id?: string
  orientation: Orientation
  order: number
  type: 'Pose' | 'Sequence'
  breathsFromStart?: number
  description?: string
  videoId?: string
  videoClip?: VideoClip
  audioClip?: AudioClip
}

export interface FlowingPose extends BaseFlowing {
  type: 'Pose'
  poseId: string
  name?: string
  image?: string
  breath: 'inhale' | 'exhale'
  holdFor: number
}

export interface FlowingSequence extends BaseFlowing {
  type: 'Sequence'
  name?: string
  sequenceId: string
  repeats?: number
  poses: FlowingPose[]
}

export type FlowingMovement = FlowingPose | FlowingSequence

export type SequencedPose = {
  sequenceId?: string
  poseId: string
  order: number
  breath: 'inhale' | 'exhale'
  // hold?: boolean
  holdFor: number
}

export type Saved = { id: string; createdAt?: Date; updatedAt?: Date }
export type Owned = { createdBy: string }

export type UnsavedPose = {
  name: string
  sanskrit?: string
  image?: string
  audio?: string[]
  aka?: string[]
  level: number
  description: string
  focus: Focus[]
  chakra: string[]
  // flagForAudio?: boolean
  from?: string[]
  to?: string[]
  videos?: VideoClip[]
  audioClips?: AudioClip[]
  breath: 'inhale' | 'exhale'
  holdFor: number
  centered: boolean
}

export type UnsavedSequence = {
  name: string
  description?: string
  audio?: string[]
  audioClips?: AudioClip[]
  poses: SequencedPose[]
  createdBy: string
  videos?: VideoClip[]
  public?: boolean
  // centered: boolean
}

export type UnsavedTransition = {
  to: string
  from: string
  description?: string
  audio?: string[]
  videos?: VideoClip[]
}

export type UnsavedFlow = {
  name: string
  description: string
  transitions: FlowingTransition[]
  createdBy?: string
  public?: boolean
}

type media = {
  id?: string
  createdAt?: Date
  updatedAt?: Date
  type: 'Pose' | 'Sequence' | 'Transition' | string
  source: 'youtube' | string
  userId: string
}

export type MemberInitReponse = UserInitResponse & {
  poses: Pose[]
  sequences: Sequence[]
  transitions: Transition[]
}

export type AdminInitResponse = MemberInitReponse & {
  audio: AudioClip[]
  videos: VideoClip[]
}

export type UserInitResponse = {
  user: AuthedUser
  startingPoses: Pose[]
  flows: Flow[]
}

export type VideoClip = media & {
  start: number
  end: number
  videoId: string
  poseId?: string
  transitionId?: string
  sequenceId?: string
  orientation: Orientation // orientation of the pose in relation to the body
  facing: Facing // orientation of the person in relation to the camera
}

export type AudioClip = media & {
  orientation?: Orientation // orientation of the pose in relation to the body
  url: string
  duration: number
  transcript?: string
}

export type Pose = Saved & UnsavedPose
export type Sequence = Saved & UnsavedSequence
export type Transition = Saved & UnsavedTransition
export type Flow = Owned & Saved & UnsavedFlow

export type Practice = {
  name: string
  by: string
  flows: Flow[]
}

export type AuthedUser = {
  credential?: string
  email: string
  id: string
  name: string
  firstName: string
  lastName: string
  pictureUrl: string
  createdAt: Date
  lastLogin: Date
  lastLoginFrom?: string
  subscriptionLevel: 'FREE' | 'MEMBER' | 'ADMIN'
}

export type Subscription = {
  id: string
  userId: string
  type: 'FREE' | 'MEMBER' | 'ADMIN'
}

export type DeleteAudioResponse = {
  deletedId: string
  deletedFrom: {
    poses: Pose[]
    transitions: Transition[]
    sequences: Sequence[]
  }
}

export type AudioUploadParams = {
  transitionId?: string
  poseId?: string
  sequenceId?: string
  duration: number // in ms
  type?:
    | 'pose.default.name'
    | 'pose.default.desc'
    | 'pose.default.desc.right'
    | 'pose.default.desc.left'
    | 'inhale'
    | 'exhale'
    | 'hold'
    | 'instruction'
    | 'affirmation'
    | string
}

export type MovementType = 'Pose' | 'Sequence' | 'Transition'

export type ObjectType =
  | MovementType
  | 'Flow'
  | 'Audio'
  | 'FlowingMovement'
  | 'User'
  | 'VideoClip'

// websocket related types

export type Action = {
  action: string
  // type: 'draw' | 'shuffle'
  // shuffleType?: ShuffleType
}

export type ActionRequest = {
  callId: string
  action: Action
  readingId: string
}

export type ActionRequestEventType = {
  type: 'action-request'
  data: ActionRequest[]
}

export type RelayServiceEventType =
  | 'relay-ready'
  | 'user-input'
  | 'error'
  | 'user-update'
  | 'action-request'
  | 'action-response'
  | 'suggested-intentions'
  | 'described-sequence'
  | 'describe-sequence'
  | 'generated-sequence'
  | 'generate-sequence'
  | 'generated-sequence-instructions'
  | 'generate-sequence-instructions'
  | 'generate-transition-audio'
  | 'generated-transition-audio'

export type RelayServiceEvent =
  | ActionRequestEventType
  | {
      type: RelayServiceEventType
      data: any
    }

export type SuggestedIntentions = {
  intention: string[]
  startingPose: { name: string; id: string }
}

export type SequenceInstruction = {
  fromPose: { poseName: string; poseId: string; flowingId?: string }
  toPose: { poseName: string; poseId: string; flowingId?: string }
  instructions: string
}

export type FlowStage =
  | 'grounding'
  | 'warming'
  | 'heating'
  | 'cooling'
  | 'savasana'

export type GenerateSequenceParams = {
  level: number
  focus: Focus[]
  stage: FlowStage
  intention?: string
  fromPoseId?: string
  fromPoseIndex: number
  previousPoses: AIGeneratedMovement[]
}

export type GenerateSequenceInstructionsParams = {
  level: number
  focus: Focus[]
  stage: FlowStage
  intention?: string
  sequenceId?: string
  movements?: AIGeneratedMovement[]
}

export type GenerateMovementInstructionsParams = {
  level: number
  focus: Focus[]
  stage: FlowStage
  intention?: string
  sequenceId?: string
  movements: AIGeneratedMovement
}

export type GenerateTransitionAudioParams = {
  transitions: FlowingTransition[]
  flowId: string
}

export function findDifference(obj1: any, obj2: any) {
  if (obj1 === obj2) return []
  if (obj1 === null || obj2 === null) return []
  if (obj1 === undefined || obj2 === undefined) return []

  const diffKeys = Object.keys(obj1).reduce((acc: string[], key) => {
    if (!(key in obj2) || obj1[key] !== obj2[key]) {
      acc.push(key)
    }
    return acc
  }, [])

  Object.keys(obj2).forEach((key) => {
    if (!(key in obj1) || obj1[key] !== obj2[key]) {
      if (!diffKeys.includes(key)) {
        diffKeys.push(key)
      }
    }
  })

  return diffKeys
}

export function isFlowingPose(
  movement: FlowingMovement,
): movement is FlowingPose {
  return movement.type === 'Pose'
}

export function isFlowingSequence(
  movement: FlowingMovement,
): movement is FlowingSequence {
  return movement.type === 'Sequence'
}

export function isSavedFlow(obj: any): obj is Flow {
  return obj.id !== undefined
}
