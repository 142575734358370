import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import { Button } from '@mui/joy'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  const login = async () => {
    await loginWithRedirect({
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: '_self',
        })
      },
    })
  }

  return (
    <Button variant="outlined" onClick={login}>
      Log in
    </Button>
  )
}

export default LoginButton
