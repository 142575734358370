import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import PageWrapper from './components/public/page-wrapper'
import PublicFlows from './components/public/flows'
import { FlowLoader, FlowPlayer } from './components/public/flow-player'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <App>
        <PageWrapper>
          <PublicFlows />
          {/* {!currentFlowId && <PublicFlows />}
          {currentFlowId && <FlowPlayer />} */}
        </PageWrapper>
      </App>
    ),
  },
  {
    path: '/flow/:flowId',
    element: (
      <App>
        <PageWrapper>
          <FlowPlayer />
        </PageWrapper>
      </App>
    ),
    loader: FlowLoader,
  },
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
