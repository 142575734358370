import { useAuth0 } from '@auth0/auth0-react'
import { Browser } from '@capacitor/browser'
import config from '../../../service/config'
import { Button } from '@mui/joy'
import useYogaStore from '../../../hooks/use-yoga-store'

const LogoutButton = () => {
  const { logout } = useAuth0()

  const setCurrentUser = useYogaStore((state) => state.setCurrentUser)

  const doLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: config.auth0.redirectUri.logout,
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: '_self',
        })

        setCurrentUser(undefined)
      },
    })
  }

  return (
    <Button variant="outlined" onClick={doLogout}>
      Log out
    </Button>
  )
}

export default LogoutButton
