import { Flow, FlowingPose, FlowingTransition, isFlowingPose } from '../types'
import { EventEmitter } from 'events'

// export type MovementVideoClip = VideoClip & { movementId: string };
export enum FlowPlayerEvent {
  LOADING = 'loading',
  LOADED = 'loaded',
  PLAYING = 'playing',
  PAUSED = 'paused',
  // NEW_POSE = 'newPose',
  COUNTDOWN = 'countdown',
  COMPLETED = 'completed',
  SEQUENCE_START = 'sequenceStart',
  TRANSITION_START = 'transitionStart',
  INTENTION_START = 'intentionStart',
}

// export type NewTransitionParams = {
//   pose: FlowingPose
//   nextPose?: FlowingPose
// }

class FlowTimer {
  private timer: ReturnType<typeof setTimeout> | undefined = undefined
  private start: number = 0 // original start time
  private callback: () => void
  private delay: number // remaining time for the timer
  private remainingTime: number | undefined // store remaining time when paused

  constructor(params: { callback: () => void; delay: number }) {
    const { callback, delay } = params
    this.callback = callback
    this.delay = delay
    this.remainingTime = delay // initially set remaining time to the full delay
    this.resume()
  }

  clear = () => {
    clearTimeout(this.timer)
  }

  pause = () => {
    this.clear()
    const now = Date.now()
    this.remainingTime = this.delay - (now - this.start) // store the time left
  }

  resume = () => {
    if (this.remainingTime !== undefined) {
      this.start = Date.now()
      this.timer = setTimeout(this.callback, this.remainingTime)
    }
  }
}

class FlowPlayerService extends EventEmitter {
  private transitionStart: number = 0
  private flow: Flow
  private breathLengthMS: number
  private isPaused: boolean = false
  // private loading = false
  private currentTransition = -1
  private currentTransitionTimer: FlowTimer | undefined = undefined
  private currentCountdownTimer: FlowTimer | undefined = undefined

  constructor(params: { flow: Flow; breathLengthMS: number }) {
    super()
    const { flow, breathLengthMS } = params
    this.flow = flow
    this.breathLengthMS = breathLengthMS
  }

  private setCurrentCountdownTimer = (currentTransitionMs: number) => {
    if (this.currentCountdownTimer) {
      this.currentCountdownTimer.clear()
    }

    this.currentCountdownTimer = new FlowTimer({
      callback: () => {
        const timeLeft =
          currentTransitionMs - (Date.now() - this.transitionStart)
        this.emit(FlowPlayerEvent.COUNTDOWN, timeLeft)
        this.setCurrentCountdownTimer(currentTransitionMs)
      },
      delay: 1000,
    })
  }

  private setCurrentTransitionTimer = (
    transitionLengthMS: number,
    transition: FlowingTransition,
  ) => {
    // console.log(`setCurrentTransitionTimer`, transition.to?.name)
    if (this.currentTransitionTimer) {
      this.currentTransitionTimer.clear()
    }

    if (transition.to?.type === 'Pose') {
      // console.log(
      //   `doing next transition to ${transition.to.name} in ${transitionLengthMS} ms`,
      // )
      this.currentTransitionTimer = new FlowTimer({
        callback: () => this.doTransition(transition),
        delay: transitionLengthMS,
      })
    }
  }

  public get flowName(): string {
    return this.flow.name
  }

  public get flowDescription(): string {
    return this.flow.description
  }

  public get flowId(): string {
    return this.flow.id
  }

  public get createdBy(): string {
    return this.flow.createdBy
  }

  public pause(): void {
    this.isPaused = true
    this.currentTransitionTimer?.pause()
    this.currentCountdownTimer?.pause()
    this.emit(FlowPlayerEvent.PAUSED)
  }

  public resume(): void {
    if (!this.isPaused) return
    this.isPaused = false

    // Resume timers if they exist
    this.currentTransitionTimer?.resume()
    this.currentCountdownTimer?.resume()
    this.emit(FlowPlayerEvent.PLAYING, true)
  }

  public end(): void {
    this.currentTransitionTimer?.clear()
    this.currentCountdownTimer?.clear()
    this.currentTransition = -1
  }

  public start(): void {
    this.currentTransition = 0
    const fromPose = this.flow.transitions[this.currentTransition].from
    const audioDuration = fromPose.audioClip?.duration ?? 0
    const breathsDuration =
      fromPose.type === 'Pose'
        ? fromPose.holdFor * this.breathLengthMS
        : this.breathLengthMS / 2
    const intentionTimeMs =
      audioDuration > breathsDuration ? audioDuration : breathsDuration
    this.doIntention(
      this.flow.transitions[this.currentTransition].from as FlowingPose,
      this.flow.transitions[this.currentTransition],
      intentionTimeMs,
    )
    setTimeout(() => {
      this.doTransition(this.flow.transitions[this.currentTransition])
    }, intentionTimeMs)
    // this.doTransition(this.flow.transitions[this.currentTransition])
    this.emit(FlowPlayerEvent.PLAYING, true)
  }

  public nextTransition(): void {
    if (this.currentTransition + 1 >= this.flow.transitions.length) {
      this.end()
      return
    }
    this.doTransition(this.flow.transitions[this.currentTransition + 1])
  }

  public restart(): void {
    this.end()
    this.emit(FlowPlayerEvent.PLAYING, false)
    this.start()
  }

  public doIntention = (
    pose: FlowingPose,
    nextTransition: FlowingTransition,
    intentionLengthMS: number,
  ) => {
    this.emit(FlowPlayerEvent.INTENTION_START, { pose, nextTransition })
    this.transitionStart = Date.now()
    this.setCurrentCountdownTimer(intentionLengthMS)
    this.emit(FlowPlayerEvent.PLAYING, true)
  }

  // public calculateFlowLength = (): number => {
  //   const transitionTimeMs = this.flow.transitions.reduce((acc, transition) => {
  //     if (transition.to?.type === 'Pose') {
  //       const transitionAudioDuration = transition.audio?.duration ?? 0

  //       const nextPoseBreathsDuration =
  //         transition.to.holdFor > 0
  //           ? this.breathLengthMS * transition.to.holdFor
  //           : this.breathLengthMS / 2
  //       // console.log(`nextPoseBreathsDuration: ${nextPoseBreathsDuration / 1000} s`)
  //       const stayHereFor =
  //         transitionAudioDuration > nextPoseBreathsDuration
  //           ? transitionAudioDuration + 3000 // add 3 seconds to the transition so the audio can finish and not rush the next pose
  //           : nextPoseBreathsDuration

  //       acc += stayHereFor
  //     }
  //     return acc
  //   }, 0)

  //   const firstPose = this.flow.transitions[0].from as FlowingPose
  //   const firstPoseBreathsDuration =
  //     firstPose.holdFor > 0
  //       ? this.breathLengthMS * firstPose.holdFor
  //       : this.breathLengthMS / 2

  //   const firstPoseAudioDuration = firstPose.audioClip?.duration ?? 0

  //   const intentionTimeMs =
  //     firstPoseAudioDuration > firstPoseBreathsDuration
  //       ? firstPoseAudioDuration + 3000
  //       : firstPoseBreathsDuration

  //   return intentionTimeMs + transitionTimeMs
  // }

  public doTransition = (transition: FlowingTransition) => {
    if (this.isPaused) return // Prevent starting the transition when paused
    if (this.currentTransition + 1 >= this.flow.transitions.length) {
      this.emit(FlowPlayerEvent.COMPLETED)
      this.end()
      return
    }

    this.currentTransition++
    const nextTransition = this.flow.transitions[this.currentTransition]

    if (nextTransition?.to && !isFlowingPose(nextTransition.to)) {
      throw new Error(`No pose definition in next pose`)
    }

    const transitionAudioDuration = transition.audio?.duration ?? 0
    const nextPose = transition?.to as FlowingPose
    const nextPoseBreathsDuration =
      nextPose.holdFor > 0
        ? this.breathLengthMS * nextPose.holdFor
        : this.breathLengthMS / 2
    const stayHereFor =
      transitionAudioDuration > nextPoseBreathsDuration
        ? transitionAudioDuration + 3000 // add buffer time
        : nextPoseBreathsDuration

    this.transitionStart = Date.now()

    this.emit(FlowPlayerEvent.TRANSITION_START, { transition, nextTransition })
    this.emit(FlowPlayerEvent.PLAYING, true)

    // Set new transition and countdown timers
    this.setCurrentTransitionTimer(stayHereFor, nextTransition)
    this.setCurrentCountdownTimer(stayHereFor)
  }
}

export default FlowPlayerService
