import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import useYogaStore from '../../hooks/use-yoga-store'
import FlowPlayerService, { FlowPlayerEvent } from '../../service/flow-player'
import {
  ButtonGroup,
  DialogContent,
  DialogTitle,
  Dropdown,
  Grid,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Select,
  Option,
  Sheet,
  Typography,
  Box,
  Slider,
} from '@mui/joy'
import ReactPlayer from 'react-player'
import {
  Fullscreen,
  PauseCircleOutlineTwoTone,
  PlayCircle,
  PlayCircleFilledOutlined,
  Refresh,
  SkipNext,
} from '@mui/icons-material'
import { ServiceFactory } from '../../service/factory'
import {
  AudioClip,
  AuthedUser,
  Flow,
  FlowingPose,
  FlowingTransition,
} from '../../service/types'
import LotusPrompt from './lotus/lotus-prompt'
import { useLoaderData } from 'react-router-dom'
import './flow-player.css'

const FlowLoader = ({
  params,
}: {
  params: { flowId?: string }
}): { flowId: string } => {
  if (!params.flowId) {
    throw new Error('Flow ID is required')
  }
  return { flowId: params.flowId }
}

const FlowPlayer = () => {
  const currentUser = useYogaStore((state) => state.currentUser)

  const { flowId } = useLoaderData() as { flowId: string }

  const flows = useYogaStore((state) => state.flowService.flows)
  const [flow, setFlow] = useState<Flow | undefined>()

  const [player, setPlayer] = useState<HTMLDivElement>()
  const [playing, setPlaying] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [currentPoseVideoUrl, setCurrentPoseVideoUrl] = useState<
    string | undefined
  >()
  const [currentAudio, setCurrentAudio] = useState<AudioClip | undefined>()
  const [currentPose, setCurrentPose] = useState<FlowingPose>()
  const [nextPose, setNextPose] = useState<FlowingPose>()
  const [timeLeft, setTimeLeft] = useState<number>()
  const [audio, setAudio] = useState<HTMLAudioElement>()
  const [musicPlayer, setMusicPlayer] = useState<ReactPlayer>()
  const [flowCompletedOpen, setFlowCompletedOpen] = useState<boolean>(false)

  const flowPlayer = useRef<FlowPlayerService | undefined>(undefined)
  const [musicVolume, setMusicVolume] = useState(0.5)
  const [promptVolume, setPromptVolume] = useState(0.5)

  const audioChoices = {
    'https://www.youtube.com/watch?v=rR2jsNdtlSA':
      'A Message from the Stars 🌌',
    'https://www.youtube.com/watch?v=1LHs8BfH6yU': 'Ambient Introvert 🎶',
    'https://www.youtube.com/watch?v=zBGVbVmxU_U': 'Dystopian Soundscapes 🎵',
    'https://www.youtube.com/watch?v=mx-5WNASaf0': 'Space Ambient Music 🚀',
    'https://www.youtube.com/watch?v=pSaB6Ku6UVI': 'Deep Meditation 🧠✨',
    'https://www.youtube.com/watch?v=azfQScEdDa0': 'Power Flow sounds 🧘‍♂️',
    'https://www.youtube.com/watch?v=uY_-JQMtW6Y': 'Upbeat Yoga Sounds 🎶',
    'https://www.youtube.com/watch?v=L3kL7zwNYP8':
      'Alone | Beautiful Chill Music Mix 🎧',
  }

  const [selectedAudio, setSelectedAudio] = useState<string>(
    'https://www.youtube.com/watch?v=L3kL7zwNYP8',
  )

  const doSetPromptVolume = (volume: number) => {
    if (audio) {
      audio.volume = volume
      setPromptVolume(volume)
    }
  }

  const fullScreen = useCallback(() => {
    if (player?.requestFullscreen) {
      player.requestFullscreen()
    }
  }, [player])

  useEffect(() => {
    const fetchFlow = async () => {
      const playableFlow = await ServiceFactory.flow.play(flowId, currentUser)
      console.log('playableFlow', playableFlow)
      setFlow(playableFlow)
    }

    if (flowId) {
      fetchFlow()
    }
  }, [flowId, currentUser, flows])

  useEffect(() => {
    if (currentAudio) {
      if (audio) {
        if (!audio.paused) {
          audio.pause()
        }
        audio.src = `https://storage.googleapis.com/stoner-yoga/${currentAudio.url}`
        audio.play()
      }
    }
  }, [currentAudio, audio])

  const moveToNextPose = () => {
    setPlaying(true)
    flowPlayer.current?.nextTransition()
  }

  const start = () => {
    setPlaying(true)
    flowPlayer.current?.start()
  }

  const pause = () => {
    setPlaying(false)
    flowPlayer.current?.pause()
    audio?.pause()
  }

  const resume = () => {
    flowPlayer.current?.resume()
    audio?.play()
  }

  const restart = () => {
    flowPlayer.current?.restart()
  }

  const setPlayerRef = (elem: HTMLDivElement | null) => {
    if (elem) {
      setPlayer(elem)
    }
  }

  const setAudioRef = (elem: HTMLAudioElement | null) => {
    if (elem) {
      setAudio(elem)
      elem.volume = promptVolume
    }
  }

  const setMusicPlayerRef = (elem: ReactPlayer | null) => {
    if (elem) {
      setMusicPlayer(elem)
    }
  }

  useEffect(() => {
    const setupFlowPlayer = async (currentUser?: AuthedUser) => {
      if (!flow) return
      // const flow = await ServiceFactory.flow.play(currentFlowId, currentUser)

      // if (flowPlayer.current && flowPlayer.current.curre()) {
      //   flowPlayer.current.end()
      //   flowPlayer.current.removeAllListeners()
      // }
      flowPlayer.current = new FlowPlayerService({
        flow,
        breathLengthMS: 4750,
      })

      flowPlayer.current.on(FlowPlayerEvent.LOADING, () => {
        // console.log('flow player loading')
      })

      flowPlayer.current.on(FlowPlayerEvent.LOADED, () => {
        // console.log('flow player loaded')
      })

      flowPlayer.current.on(FlowPlayerEvent.PLAYING, (playing: boolean) => {
        // console.log(`flow player playing: ${playing}`)
        setPlaying(playing)
        setPaused(false)
      })

      flowPlayer.current.on(FlowPlayerEvent.PAUSED, () => {
        // console.log(`flow player paused: `)
        setPaused(true)
      })

      flowPlayer.current.on(FlowPlayerEvent.COUNTDOWN, (timeLeft: number) => {
        setTimeLeft(timeLeft)
      })

      flowPlayer.current.on(FlowPlayerEvent.COMPLETED, () => {
        setFlowCompletedOpen(true)
        setTimeLeft(undefined)
        setPlaying(false)
        setCurrentPoseVideoUrl(undefined)
        setCurrentPose(undefined)
        setNextPose(undefined)
      })

      flowPlayer.current.on(
        FlowPlayerEvent.TRANSITION_START,
        (params: {
          transition: FlowingTransition
          nextTransition: FlowingTransition
        }) => {
          // console.log(`TRANSITION_START`, params)
          setCurrentAudio(params.transition.audio)
          // console.log('setting current pose', params.transition.to?.name)
          setCurrentPose(params.transition.to as FlowingPose)
          // console.log('setting next pose', params.nextTransition.to?.name)
          setNextPose(params.nextTransition.to as FlowingPose)
        },
      )

      flowPlayer.current.on(
        FlowPlayerEvent.INTENTION_START,
        (params: { pose: FlowingPose; nextTransition: FlowingTransition }) => {
          setCurrentAudio(params.pose.audioClip)
          // console.log('setting current pose', params.pose.name)
          setCurrentPose(params.pose)
          // console.log('setting next pose', params.nextTransition.to?.name)
          setNextPose(params.nextTransition.to as FlowingPose)
        },
      )
    }

    setupFlowPlayer(currentUser)
    // console.log('returning cleaning up flow player')
    return () => {
      flowPlayer.current?.removeAllListeners()
      flowPlayer.current?.end()
    }
  }, [currentUser, flow])

  const posePreview = () => {
    if (currentPose) {
      return (
        <Sheet
          sx={{
            margin: '0px auto',
            position: 'relative',
            top: '175px',
            backgroundColor: '#222',
          }}
        >
          <img alt="" src={`/poses/${currentPose.image}`}></img>
        </Sheet>
      )
    }
  }

  const displayLotusPrompt = () => {
    return (
      <LotusPrompt
        base={
          !currentPose
            ? true
            : currentPose?.orientation === 'center'
              ? true
              : false
        }
        bottomRight={
          !currentPose
            ? true
            : currentPose?.orientation === 'right'
              ? true
              : false
        }
        topRight={
          !currentPose
            ? true
            : currentPose?.orientation === 'right'
              ? true
              : false
        }
        bottomLeft={
          !currentPose
            ? true
            : currentPose?.orientation === 'left'
              ? true
              : false
        }
        topLeft={
          !currentPose
            ? true
            : currentPose?.orientation === 'left'
              ? true
              : false
        }
      />
    )
  }

  return (
    <>
      <Grid
        xs={12}
        container
        ref={(element) => setPlayerRef(element)}
        className="player flow"
        height={'100%'}
        sx={{ height: '100vh' }}
      >
        <audio ref={(element) => setAudioRef(element)}></audio>
        {
          <ReactPlayer
            style={{
              display: 'none',
              margin: '0px auto',
              position: 'relative',
              pointerEvents: 'none',
            }}
            // muted
            ref={(element) => setMusicPlayerRef(element)}
            pip={false}
            volume={musicVolume}
            height={700}
            controls={false}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  rel: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                },
              },
            }}
            playing={playing}
            url={selectedAudio}
          ></ReactPlayer>
        }
        <Grid
          container
          xs={12}
          sx={{
            margin: '1rem 2rem',
            width: '100%',
          }}
        >
          <Grid xs={12} textAlign={'center'}>
            <Typography level="h1">{flow?.name}</Typography>
          </Grid>
          <Grid xs={12} textAlign={'center'}>
            <Typography level="body-xs">{flow?.description}</Typography>
          </Grid>
          <Grid textAlign="center" xs={12}>
            <Typography level="h2">
              {currentPose?.name ?? 'Press play to begin'}
            </Typography>
          </Grid>
        </Grid>
        {!currentPoseVideoUrl && posePreview()}
        <Grid container height={'40vw'} xs={12} textAlign="center">
          {displayLotusPrompt()}
        </Grid>
        <Grid xs={12} textAlign={'center'}>
          <h2>
            {nextPose && `${nextPose.name} in `}{' '}
            {timeLeft && Math.ceil(timeLeft / 1000).toFixed(0)}
          </h2>
        </Grid>

        <Grid
          container
          sx={{
            position: 'relative',
            display: 'grid',
            bottom: '0px',
            width: '100%',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center', // Center the contents horizontally
            padding: '1rem', // Add padding for spacing
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional background for better visibility
          }}
        >
          <ButtonGroup size="lg" sx={{ justifySelf: 'center' }}>
            {/* <IconButton onClick={() => fullScreen()}>
            <Fullscreen />
          </IconButton> */}
            <IconButton onClick={() => restart()}>
              <Refresh />
            </IconButton>
            {!playing && !paused && (
              <IconButton onClick={() => start()}>
                <PlayCircle />
              </IconButton>
            )}
            {playing && !paused && (
              <IconButton onClick={() => pause()}>
                <PlayCircleFilledOutlined />
              </IconButton>
            )}
            {paused && (
              <IconButton onClick={() => resume()}>
                <PauseCircleOutlineTwoTone />
              </IconButton>
            )}
            <IconButton onClick={() => moveToNextPose()}>
              <SkipNext />
            </IconButton>
          </ButtonGroup>
          <Grid xs={12}>
            <Select
              sx={{ maxWidth: '300px', margin: '0 auto' }}
              value={selectedAudio}
              onChange={(e, value) => setSelectedAudio(value as string)}
            >
              {Object.entries(audioChoices).map(([url, label]) => (
                <Option value={url}>{label}</Option>
              ))}
            </Select>
          </Grid>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box width={275} sx={{ alignSelf: 'center' }}>
              <Slider
                size="sm"
                marks={[
                  {
                    value: 0,
                    label: '',
                  },
                  {
                    value: 100,
                    label: '',
                  },
                ]}
                step={11}
                value={Math.abs(promptVolume ?? 0) * 100}
                // valueLabelDisplay="auto"
                min={0}
                max={100}
                onChange={(_: Event, newValue: number | number[]) => {
                  console.log('newValue', newValue)
                  // setVolumeBalance(newValue as number)
                  // set the voice
                  doSetPromptVolume(Math.abs(newValue as number) / 100)
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  level="body-sm"
                  onClick={() => setPromptVolume(0)}
                  sx={{ cursor: 'pointer' }}
                >
                  mute voice
                </Typography>
                <Typography
                  level="body-sm"
                  onClick={() => setPromptVolume(1)}
                  sx={{ cursor: 'pointer' }}
                >
                  voice {Math.ceil((audio?.volume ?? 0) * 100)}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box width={275} sx={{}}>
              <Slider
                size="sm"
                marks={[
                  {
                    value: 0,
                    label: '',
                  },
                  {
                    value: 100,
                    label: '',
                  },
                ]}
                step={11}
                value={Math.abs(musicVolume ?? 0) * 100}
                // valueLabelDisplay="auto"
                min={0}
                max={100}
                onChange={(_: Event, newValue: number | number[]) => {
                  console.log('newValue', newValue)
                  setMusicVolume(Math.abs(newValue as number) / 100)
                }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  level="body-sm"
                  onClick={() => setMusicVolume(0)}
                  sx={{ cursor: 'pointer' }}
                >
                  mute music
                </Typography>
                <Typography
                  level="body-sm"
                  onClick={() => setMusicVolume(1)}
                  sx={{ cursor: 'pointer' }}
                >
                  music {Math.ceil((musicVolume ?? 0) * 100)}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Modal
          open={flowCompletedOpen}
          onClose={() => setFlowCompletedOpen(false)}
        >
          <ModalDialog>
            <ModalClose />
            <DialogTitle>
              <Typography level="h4">Flow Completed</Typography>
            </DialogTitle>
            <DialogContent>
              <Typography level="body-md">
                Congratulations! You have completed the flow.
              </Typography>
            </DialogContent>
          </ModalDialog>
        </Modal>
      </Grid>
    </>
  )
}

export { FlowPlayer, FlowLoader }
