import { create } from 'zustand'
import { AuthedUser, Flow } from '../service/types'

interface YogaStore {
  currentUser: AuthedUser | undefined
  setCurrentUser: (user: AuthedUser | undefined) => void
  // currentFlowId: string | undefined
  // setCurrentFlowId: (flowId: string | undefined) => void
  flowService: {
    flows: { [key: string]: Flow }
    setFlows: (flows: Flow[]) => void
  }
}

const useYogaStore = create<YogaStore>((set) => ({
  currentUser: undefined,
  setCurrentUser: (user: AuthedUser | undefined) => set({ currentUser: user }),
  // currentFlowId: undefined,
  // setCurrentFlowId: (flowId: string | undefined) =>
  //   set({ currentFlowId: flowId }),
  flowService: {
    flows: {},
    setFlows: (flows) => {
      const flowsMap = flows.reduce<{ [key: string]: Flow }>((acc, flow) => {
        acc[flow.id] = flow
        return acc
      }, {})
      set((state) => ({
        flowService: {
          ...state.flowService,
          flows: flowsMap,
        },
      }))
    },
  },
}))

export default useYogaStore
