import React, { useCallback, useEffect, useMemo } from 'react'
import { ServiceFactory } from '../../service/factory'
import styles from './flows.module.css'
import useYogaStore from '../../hooks/use-yoga-store'
import {
  Card,
  CardActions,
  CardContent,
  CardCover,
  Grid,
  IconButton,
  Typography,
} from '@mui/joy'
import { PlayArrow } from '@mui/icons-material'
import LoginButton from './auth/login-button'
import { Flow, FlowingPose } from '../../service/types'
import { useNavigate } from 'react-router-dom'
import { CardHeader } from '@mui/material'
import LotusPrompt from './lotus/lotus-prompt'

const PublicFlows = () => {
  const currentUser = useYogaStore((state) => state.currentUser)
  const flows = useYogaStore((state) => state.flowService.flows)
  const setFlows = useYogaStore((state) => state.flowService.setFlows)

  useEffect(() => {
    ServiceFactory.flow.listPublic(setFlows)
  }, [setFlows])

  const navigate = useNavigate()
  const breathLengthMS = 4500

  const calcFlowStats = (flow: Flow): { length: number; level: number } => {
    const transitionTimeMs = flow.transitions.reduce((acc, transition) => {
      if (transition.to?.type === 'Pose') {
        const transitionAudioDuration = transition.audio?.duration ?? 0
        const nextPoseBreathsDuration =
          transition.to.holdFor > 0
            ? breathLengthMS * transition.to.holdFor
            : breathLengthMS / 2
        const stayHereFor =
          transitionAudioDuration > nextPoseBreathsDuration
            ? transitionAudioDuration + 3000
            : nextPoseBreathsDuration

        acc += stayHereFor
      }
      return acc
    }, 0)

    const firstPose = flow.transitions[0].from as FlowingPose
    const firstPoseBreathsDuration =
      firstPose.holdFor > 0
        ? breathLengthMS * firstPose.holdFor
        : breathLengthMS / 2
    const firstPoseAudioDuration = firstPose.audioClip?.duration ?? 0
    const intentionTimeMs =
      firstPoseAudioDuration > firstPoseBreathsDuration
        ? firstPoseAudioDuration + 3000
        : firstPoseBreathsDuration

    // const flowLevelTotal = flow.transitions.reduce((acc, transition) => {
    //   if (transition.to?.type === 'Pose') {
    //     const pose = transition.to as FlowingPose
    //     acc += pose.level
    //   }
    //   return acc
    // }

    return { length: intentionTimeMs + transitionTimeMs, level: 100 }
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1, padding: '0 1rem' }}>
      {/* Hero Section */}
      {/* Hero Section */}
      <LotusPrompt
        containerClass="landing-page-lotus-container"
        base={true}
        bottomLeft={true}
        bottomRight={true}
        topLeft={true}
        topRight={true}
      />

      <Grid xs={12} textAlign="center">
        <Card
          sx={{
            flexGrow: 1,
            cursor: 'pointer',
            textAlign: 'justify',
            opacity: 0.8,
          }}
          variant="outlined"
          // className={`inset glowing ${styles.card} `}
        >
          <CardCover
            sx={{
              backdropFilter: 'blur(10px) saturate(50%)',
              opacity: 0.25,
              filter: 'blur(10px)  saturate(150%)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              backgroundImage: `url('/card-cover-3.webp')`,
              backgroundSize: 'cover',
            }}
          ></CardCover>
          <CardContent>
            <h1 style={{ textAlign: 'center' }}>
              Hello, {currentUser?.name ?? 'Friend'}
            </h1>
            <h2 style={{ textAlign: 'center' }}>Welcome to Yoga with ntntn</h2>
            <p>
              Transform your practice with guided, intention-driven yoga
              sequences.
            </p>
            <p>
              Designed to connect mind and body, our sequences offer more than
              just movement. Each flow begins with an intention, grounding you
              in purpose, while offering variety and balance to support your
              growth.
            </p>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid xs={12} textAlign="center">
        <div className={styles.logoContainer}>
          <div className={`${styles.pulseShadow} ${styles.logoChild}`}></div>
          <div className={`${styles.pulse} ${styles.logoChild}`}></div>
          <div className={`${styles.logo} ${styles.logoChild}`}></div>
        </div>
      </Grid> */}
      {/* Introduction and CTA */}
      {/* CTA for Guest Users */}
      {!currentUser && (
        <Grid xs={12} textAlign="center">
          <Card>
            <h3>
              Discover purpose-driven yoga flows. Choose a flow below to begin,
              or create a free account for more options!
            </h3>
            <LoginButton />
          </Card>
        </Grid>
      )}

      {/* CTA for Logged-in Users */}
      {currentUser && (
        <Grid xs={12} textAlign="center">
          <h3>Pick one of the flows below to try us out</h3>
        </Grid>
      )}

      {/* Yoga Flows List */}
      <Grid
        container
        xs={12}
        alignItems={'center'}
        justifySelf={'center'}
        textAlign={'center'}
      >
        {Object.values(flows).map((flow) => {
          const flowStats = calcFlowStats(flow)
          return (
            <Grid xs={12} container key={flow.id}>
              <Card
                sx={{
                  flexGrow: 1,
                  margin: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(`/flow/${flow.id}`)
                }}
                variant="outlined"
                className={`outset glowing ${styles.card} `}
              >
                <CardCover
                  sx={{
                    backdropFilter: 'blur(10px) saturate(50%)',
                    opacity: 0.25,
                    filter: 'blur(5px)  saturate(150%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    backgroundImage: `url('/card-cover.webp')`,
                    backgroundSize: 'cover',
                  }}
                ></CardCover>

                <CardContent>
                  <h2>{flow.name}</h2>
                </CardContent>
                <CardContent>{flow.description}</CardContent>
                <CardContent>
                  {flow.transitions.length} poses, about{' '}
                  {Math.ceil(flowStats.length / (1000 * 60))} minutes
                </CardContent>
                <CardActions orientation="vertical">
                  <IconButton
                    onClick={() => {
                      navigate(`/flow/${flow.id}`)
                    }}
                  >
                    <PlayArrow />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <Card
        sx={{
          flexGrow: 1,
          margin: '1rem',
          cursor: 'pointer',
          textAlign: 'justify',
          opacity: 0.8,
        }}
        variant="outlined"
        className={`outset glowing ${styles.card} `}
      >
        <CardCover
          sx={{
            backdropFilter: 'blur(10px) saturate(50%)',
            opacity: 0.25,
            filter: 'blur(10px)  saturate(150%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backgroundImage: `url('/card-cover-3.webp')`,
            backgroundSize: 'cover',
          }}
        ></CardCover>
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>About Us</h2>
          <p>
            At The Intention Engine, we believe in the power of intention as the
            foundation of every experience we create. Each flow begins with a
            purposeful focus, set with care, that guides the process from start
            to finish. Our intention setting is more than just a starting point;
            it\'s the core of a guided manifestation that shapes every step of
            the journey. With each breath, movement, and moment, our flows are
            crafted to support and amplify your unique goals, helping you to
            bring your intentions to life. Our approach is to bring you closer
            to your truest self, with the flow as your guide toward positive
            transformation.
          </p>
        </CardContent>
      </Card>

      <Card
        sx={{
          flexGrow: 1,
          margin: '1rem',
          cursor: 'pointer',
          textAlign: 'justify',
          opacity: 0.8,
        }}
        variant="outlined"
        className={`outset glowing ${styles.card} `}
      >
        <CardCover
          sx={{
            backdropFilter: 'blur(10px) saturate(50%)',
            opacity: 0.25,
            filter: 'blur(10px)  saturate(150%)',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backgroundImage: `url('/card-cover-3.webp')`,
            backgroundSize: 'cover',
          }}
        ></CardCover>
        <CardContent>
          <h2 style={{ textAlign: 'center' }}>Coming Soon</h2>
          <p>
            Imagine setting your own intention and experiencing a flow generated
            specifically to manifest it for you. Soon, you\'ll be able to
            personalize your practice by choosing a focus that aligns with your
            unique goals and dreams, creating an experience designed to support
            and empower your journey. Set your intention, and let the flow guide
            you.
          </p>

          <p>
            Interested in knowing more? Contact us via{' '}
            <a href="mailto:hi@ntntn.io">email</a> or{' '}
            <a href="https://discord.gg/gRnWXSSpE2">Join us on Discord</a>.
          </p>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PublicFlows
